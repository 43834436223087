import React from 'react'
import DocDetails from '../Components/Document/DocDetails'

export default function docdetails() {
    return (
        <div>
            {/* <DocDetails/> */}
        </div>
    )
}
